<template>
  <app-tabs v-model="active">
    <el-tab-pane label="针灸、拔罐等预约统计" lazy>
      <stats-pane prefix="medicalNew" />
    </el-tab-pane>
    <el-tab-pane label="推拿预约统计" lazy>
      <stats-pane prefix="medicalNewTwo" />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import statsPane from './statsPane';

export default {
  name: 'stats',
  components: {
    statsPane
  },
  data() {
    return {
      active: '0'
    };
  }
};
</script>
